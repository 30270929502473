import { get, patch, post } from "../../api";

export function getTotalReport(
  filters,
  statuses,
  vat_number_keys,
  category,
  company_id,
  startingDate,
  endingDate
) {
  // console.log(filters);
  filters["organization_id"] = company_id;
  filters["document_category"] = category.toUpperCase();
  if (startingDate) filters.date_from = startingDate + "T00:00:00";
  else if (filters.date_from) delete filters.date_from;
  if (endingDate) filters.date_to = endingDate + "T00:00:00";
  else if (filters.date_to) delete filters.date_to;
  let filters_for_url = "";
  Object.keys(filters).forEach((key) => {
    filters_for_url += `&${key}=${filters[key]}`;
  });
  return post(`documents/totals?${filters_for_url}`, {
    ...(statuses !== undefined && { document_statuses: statuses }),
    ...(vat_number_keys !== undefined && { vat_number_keys: vat_number_keys }),
  });
}

export function getDocuments(
  filters,
  statuses,
  vat_number_keys,
  order_by,
  category,
  company_id,
  startingDate,
  endingDate,
  currentPage,
  rowsPerPage
) {
  filters["organization_id"] = company_id;
  filters["document_category"] = category.toUpperCase();
  if (startingDate) filters.date_from = startingDate + "T00:00:00";
  else if (filters.date_from) delete filters.date_from;
  if (endingDate) filters.date_to = endingDate + "T00:00:00";
  else if (filters.date_to) delete filters.date_to;
  let filters_for_url = "";
  Object.keys(filters).forEach((key) => {
    filters_for_url += `&${key}=${filters[key]}`;
  });
  return post(
    `documents?skip=${
      (currentPage - 1) * rowsPerPage
    }&limit=${rowsPerPage}${filters_for_url}`,
    {
      ...(statuses !== undefined && { document_statuses: statuses }),
      ...(vat_number_keys !== undefined && {
        vat_number_keys: vat_number_keys,
      }),
      ...(order_by !== undefined && { order_by: order_by }),
    }
  );
}

export function getAllTheDocuments(
  filters,
  statuses,
  vat_number_keys,
  order_by,
  category,
  company_id,
  startingDate,
  endingDate
) {
  filters["organization_id"] = company_id;
  filters["document_category"] = category.toUpperCase();
  if (startingDate) filters.date_from = startingDate + "T00:00:00";
  else if (filters.date_from) delete filters.date_from;
  if (endingDate) filters.date_to = endingDate + "T00:00:00";
  else if (filters.date_to) delete filters.date_to;
  let filters_for_url = "";
  Object.keys(filters).forEach((key) => {
    filters_for_url += `&${key}=${filters[key]}`;
  });
  return post(`documents?${filters_for_url}`, {
    ...(statuses !== undefined && { document_statuses: statuses }),
    ...(vat_number_keys !== undefined && { vat_number_keys: vat_number_keys }),
    ...(order_by !== undefined && { order_by: order_by }),
  });
}

export function getDocument(id) {
  return get(`documents/${id}`);
}

export function getDocumentMetadata() {
  return get(`metadata/document`);
}

export function postDocumentsXml(document_ids) {
  return post("documents/xml", {
    document_ids: document_ids,
  });
}

export function postColumnSettings(column, view, action) {
  return post(`users/columns`, {
    user_columns: [
      {
        label: column,
        view: view,
        is_visible: action,
      },
    ],
  });
}

export function postAadeAll(
  filters,
  statuses,
  category,
  company_id,
  startingDate,
  endingDate
) {
  filters["organization_id"] = company_id;
  filters["document_category"] = category.toUpperCase();
  if (startingDate) filters.date_from = startingDate + "T00:00:00";
  else if (filters.date_from) delete filters.date_from;
  if (endingDate) filters.date_to = endingDate + "T00:00:00";
  else if (filters.date_to) delete filters.date_to;
  let filters_for_url = "";
  Object.keys(filters).forEach((key) => {
    filters_for_url += `&${key}=${filters[key]}`;
  });
  // console.log(filters_for_url.slice(1));
  return post(`aade/send_documents?${filters_for_url.slice(1)}`, {
    document_statuses: statuses,
  });
}

export function postAadeSelected(documents) {
  let document_ids = [];
  // console.log(documents);
  documents.forEach((doc) => document_ids.push(doc.id));

  return post("documents/transmit", {
    document_ids: document_ids,
    // view: window.location.pathname.split("/")[1],
  });
}

export function postAadeSelectedCancel(documents) {
  return post("documents/cancel", {
    document_ids: [documents[0].id],
    // view: window.location.pathname.split("/")[1],
  });
}

export function postDocumentUnmatch(documents) {
  return post(`documents/manual_unmatch/${documents[0].id}`);
}

export function postMatchIndividualDocument(organization_id, document_date) {
  const searchParams = {
    organization_id: organization_id,
  };
  // document_date is string with format "xx/xx/xxxx"
  const [day, month, year] = document_date.split("/");
  const formattedDate = `${year}-${month}-${day}T00:00:00`;
  if (document_date) {
    searchParams.date_from = formattedDate;
    searchParams.document_date_from = formattedDate;
    searchParams.document_date_to = formattedDate;
  }
  const searchParamsUrl = Object.keys(searchParams).reduce(
    (acc, key) => acc + `&${key}=${searchParams[key]}`,
    ""
  );
  return post(`documents/match?${searchParamsUrl}`);
}

export function postManualInitializeDocument(document_id) {
  return post(`documents/manual_initialize?document_id=${document_id}`);
}

export function patchDocuments(document) {
  return patch(`documents/${document.id}`, document);
}

export function triggerSynchronizationBackground(organization_id) {
  return post(`documents/synchronize?organization_id=${organization_id}`);
}

export function triggerSynchronizatioByDatesBackground(
  organization_id,
  date_from,
  document_date_from,
  document_date_to,
  models
) {
  const searchParams = {
    organization_id: organization_id,
    date_from: date_from,
    document_date_from: document_date_from,
    document_date_to: document_date_to,
  };

  const searchParamsUrl = Object.keys(searchParams).reduce(
    (acc, key) => acc + `&${key}=${searchParams[key]}`,
    ""
  );
  return post(`documents/synchronize?${searchParamsUrl}`, { models: models });
}

export function triggerSynchronizationDocumentBackground(
  organization_id,
  document_date,
  erp_date_created
) {
  const searchParams = {
    organization_id: organization_id,
  };
  // document_date is string with format "xx/xx/xxxx"
  const [day, month, year] = document_date.split("/");
  const [erpYear, erpMonth, erpDay] = erp_date_created.split("-");
  const formattedDate = `${year}-${month}-${day}T00:00:00`;
  const formattedErpDateCreated = `${erpYear}-${erpMonth}-${erpDay}T00:00:00`;
  if (document_date) {
    searchParams.date_from = formattedErpDateCreated;
    searchParams.document_date_from = formattedDate;
    searchParams.document_date_to = formattedDate;
  }
  const searchParamsUrl = Object.keys(searchParams).reduce(
    (acc, key) => acc + `&${key}=${searchParams[key]}`,
    ""
  );
  return post(`documents/synchronize?${searchParamsUrl}`);
}

export function triggerAADESynchronizationDocumentBackground(
  organization_id,
  document_date
) {
  const searchParams = {
    organization_id: organization_id,
  };
  // document_date is string with format "xx/xx/xxxx"
  const [day, month, year] = document_date.split("/");
  const formattedDate = `${year}-${month}-${day}T00:00:00`;
  if (document_date) {
    searchParams.document_date_from = formattedDate;
    searchParams.document_date_to = formattedDate;
  }
  const searchParamsUrl = Object.keys(searchParams).reduce(
    (acc, key) => acc + `&${key}=${searchParams[key]}`,
    ""
  );
  // console.log(`documents/retrieve?${searchParamsUrl}`)
  return post(`documents/retrieve?${searchParamsUrl}`);
}

export function triggerAADESynchronizationBackground(organization_id) {
  return post(`documents/retrieve?organization_id=${organization_id}`);
}

export function triggerAADEMatchBackground(organization_id) {
  return post(`documents/match?organization_id=${organization_id}`);
}

export function triggerUpdateErpBackground(organization_id, documentIds) {
  if (documentIds && documentIds.length > 0) {
    const data = {
      document_ids: documentIds,
    };
    return post(`documents/update?organization_id=${organization_id}`, data);
  } else {
    const fiveDaysAgo = new Date();
    fiveDaysAgo.setDate(fiveDaysAgo.getDate() - 5);
    const dateFrom = fiveDaysAgo.toISOString().split(".")[0];
    return post(
      `documents/update?organization_id=${organization_id}&date_from=${dateFrom}`
    );
  }
}

export function triggerUploadBackground(organization_id, documentIds) {
  if (documentIds && documentIds.length > 0) {
    const data = {
      document_ids: documentIds,
    };
    return post(`documents/upload?organization_id=${organization_id}`, data);
  } else {
    return post(`documents/upload?organization_id=${organization_id}`);
  }
}

export function getRecentTasks() {
  return get(`tasks/recent`);
}
